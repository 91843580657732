import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { ReactKeycloakProvider, useKeycloak } from '@react-keycloak/web';
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate  } from 'react-router-dom';
import keycloak from './keycloak'; // Importando a configuração do Keycloak
import { useAuth } from './utils/AuthContext'; // Importando o contexto de autenticação
import { AuthProvider } from './utils/AuthContext'; // Ajuste o caminho conforme necessário

import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { CssBaseline, CssVarsProvider, Box } from "@mui/joy";
import { SnackbarProvider } from "notistack";
import { BrowserRouter } from "react-router-dom";
import { AppRouter } from './routes'; // Importando o AppRouter de routes/index.tsx
import { TopBar } from './components/topbar';

// Função para gerenciar o acesso do usuário com base nas variáveis do token
const manageUserAccess = (decodedToken: any, isTokenExpired: boolean) => {
  if (isTokenExpired) {
    console.warn('O token expirou. Faça login novamente.');
    return false;
  }

  if (!decodedToken.realm_access || !decodedToken.realm_access.roles || decodedToken.realm_access.roles.length === 0) {
    console.warn('O usuário não possui permissões associadas.');
    return false;
  }

  const requiredRole = 'user'; // Exemplo de papel
  if (!decodedToken.realm_access.roles.includes(requiredRole)) {
    console.warn(`Acesso negado. O usuário não possui o papel necessário: ${requiredRole}`);
    return false;
  }

  console.log('Acesso concedido.');
  return true;
};


const Login = () => {
  const { keycloak } = useKeycloak();
  const { decodedToken, isTokenExpired } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (decodedToken) {
      manageUserAccess(decodedToken, isTokenExpired); // Gerencia o acesso baseado no token decodificado
    }
  }, [decodedToken, isTokenExpired]);

  const login = () => keycloak?.login();
  const logout = () => keycloak?.logout();

  //navigate('/welcome');

  return (
    <div>
      <h1>Bem vindo ao Viver de Mapa</h1>

      {/*<button onClick={login}>Login</button>
      <button onClick={logout}>Logout</button>*/}

      {decodedToken && (
        <button onClick={() => alert('Ação protegida!'+decodedToken.sub)}>Botão Protegido</button>
      )}
      {decodedToken &&
        decodedToken.realm_access &&
        Array.isArray(decodedToken.realm_access.roles) &&
        decodedToken.realm_access.roles.includes('admin') && (
          <button onClick={() => navigate('/admin')}>
            Botão Protegido para admins
          </button>
        )}

      {decodedToken &&
        decodedToken.realm_access &&
        Array.isArray(decodedToken.realm_access.roles) &&
        decodedToken.realm_access.roles.includes('user') && (
          <button
            onClick={() => {
              console.log('Decoded Token:', decodedToken?.realm_access?.roles?.includes('user'));
              console.log('Token Expirado:', isTokenExpired);

              if (decodedToken?.realm_access?.roles?.includes('user')) {
                console.log('Redirecionando para /user...');
                navigate('/user');
              } else {
                console.warn('Acesso negado ou roles ausente no token.');
                console.warn('Decoded Token:', decodedToken);
                console.log('Redirecionando para /login...');
                navigate('/login');
              }
            }}
          >
            Botão Protegido para users
          </button>

        )}
    </div>
  );
};

const Profile = () => {
  const { keycloak } = useKeycloak();
  const [profile, setProfile] = useState<Keycloak.KeycloakProfile | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (keycloak?.authenticated) {
      setLoading(true);
      keycloak
        .loadUserProfile()
        .then((data) => {
          setProfile(data);
        })
        .catch((error) => {
          console.error('Erro ao carregar o perfil do usuário:', error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [keycloak]);

  if (!keycloak?.authenticated) {
    return <Navigate to="/" />;
  }

  return (
    <div>
      <h2>Perfil</h2>
      {loading ? (
        <p>Carregando perfil...</p>
      ) : profile ? (
        <div>
          <p><strong>Nome:</strong> {profile.firstName || 'Não disponível'}</p>
          <p><strong>Email:</strong> {profile.email || 'Não disponível'}</p>
        </div>
      ) : (
        <p>Não foi possível carregar os dados do perfil.</p>
      )}
    </div>
  );
};

const ProtectedRoute = ({ element }: { element: JSX.Element }) => {
  const { decodedToken, isTokenExpired } = useAuth();
  console.log('Entrou na ProtectedRoute');
  // Verificar se o token está presente e não expirado
  if (decodedToken && !isTokenExpired) {
    console.log('Entrou no if da ProtectedRoute');
    const hasAccess = manageUserAccess(decodedToken, isTokenExpired); // Lógica de acesso baseado no token
    if (hasAccess) {
      console.log('Entrou no hasAccess da ProtectedRoute');
      return element;
    }
  }

  // Redireciona para a página de login se o token não estiver válido ou o acesso for negado
  return <Navigate to="/" />;
};

// Função principal do app com Keycloak e autenticação
const App = () => (
  <BrowserRouter>
  <TopBar /> {/* A barra superior será fixa em todas as páginas */}
    <Box sx={{ marginTop: '64px' }}> {/* Ajuste o valor conforme a altura da TopBar */}
      <Routes>
        <Route path="/" element={<Login />} />
        {/* Incluindo as rotas do AppRouter, que contém as outras rotas do seu sistema */}
        <Route path="/*" element={<AppRouter />} />
      </Routes>
    </Box>
  </BrowserRouter>
);

const AppProvider = () => (
  <ReactKeycloakProvider
    authClient={keycloak}
    onEvent={(event) => {
      if (event === 'onAuthSuccess') {
        console.info('Usuário autenticado com sucesso.');
      }
    }}
  >
    <AuthProvider>
      <CssVarsProvider>
        <SnackbarProvider>
          <CssBaseline />
          <App />
        </SnackbarProvider>
      </CssVarsProvider>
    </AuthProvider>
  </ReactKeycloakProvider>
);

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <StrictMode>
    <AppProvider />
  </StrictMode>
);
