import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../utils/AuthContext'; // Ajuste o caminho conforme necessário

interface PrivateRouteProps {
  children: JSX.Element;
  requiredRole?: string; // Role necessária para acessar a rota
}

export function PrivateRoute({ children, requiredRole }: PrivateRouteProps) {
  const { decodedToken, isTokenExpired } = useAuth(); // Obtendo o token do contexto
  const location = useLocation();

  // Verificar se o token está presente e não expirado
  if (!decodedToken || isTokenExpired) {
    console.warn('Token ausente ou expirado.');
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  // Verificar se a role necessária está presente
  if (
    requiredRole &&
    (!decodedToken.realm_access?.roles ||
      !decodedToken.realm_access.roles.includes(requiredRole))
  ) {
    console.warn(`Acesso negado. Role necessária: ${requiredRole}`);
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  // Caso todas as verificações sejam atendidas
  return children;
}
