import React, { Suspense, lazy } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { PrivateRoute } from './utils';

// Lazy loading para as páginas
const WelcomePage = lazy(() => import('../pages/Welcome'));
const Home = lazy(() => import('../pages/Home'));
const LoginPage = lazy(() => import('../pages/Login'));
const LoginCallback = lazy(() => import('./LoginCallback'));
const Logout = lazy(() => import('../pages/Logout'));
const AdminPage = lazy(() => import('../pages/Admin'));
const UserPage = lazy(() =>
  import('../pages/User').catch((err) => {
    console.error('Erro ao carregar UserPage:', err);
    throw err;
  })
);
const PersonalPage = lazy(() =>
  import('./../pages/consultas/PersonalHistory').catch((err) => {
    console.error('Erro ao carregar UserPage:', err);
    throw err;
  })
);
const ProtectedPage = lazy(() => import('../pages/Protected'));

const AppRouter: React.FC = () => (
  <Suspense fallback={<div>Loading...</div>}>
    <Routes>
      <Route path="/" element={<LoginPage />} />
      <Route path="/welcome" element={<WelcomePage />} />
      <Route path="/login/callback" element={<LoginCallback />} />
      <Route path="/logout" element={<Logout />} />
      <Route path="/admin" element={<PrivateRoute requiredRole="admin"><AdminPage /></PrivateRoute>} />
      <Route path="/user" element={<PrivateRoute requiredRole="user"><UserPage /></PrivateRoute>} />
      <Route path="/personal" element={<PrivateRoute requiredRole="user"><PersonalPage /></PrivateRoute>} />
      <Route
        path="/protected/:userId"
        element={
          <PrivateRoute>
            <ProtectedPage />
          </PrivateRoute>
        }
      />
      <Route path="/home" element={<PrivateRoute><Home /></PrivateRoute>} />
      <Route path="*" element={<Navigate to="/home" replace />} />
    </Routes>
  </Suspense>
);

export { AppRouter };
