import React, { createContext, useContext, useState, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';

interface DecodedToken {
  exp?: number;
  realm_access?: { roles?: string[] };
  acr?: string;
  'allowed-origins'?: string[];
  aud?: string;
  auth_time?: number;
  azp?: string;
  email?: string;
  email_verified?: boolean;
  family_name?: string;
  given_name?: string;
  iat?: number;
  iss?: string;
  jti?: string;
  name?: string;
  preferred_username?: string;
  resource_access?: Record<string, any>;
  scope?: string;
  sid?: string;
  sub?: string;
  typ?: string;
  [key: string]: any;  // Para cobrir possíveis campos adicionais
}

interface AuthContextType {
  decodedToken: DecodedToken | null;
  setDecodedToken: React.Dispatch<React.SetStateAction<DecodedToken | null>>;
  isTokenExpired: boolean;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [decodedToken, setDecodedToken] = useState<DecodedToken | null>(null);
  const [isTokenExpired, setIsTokenExpired] = useState(false);

  // Função para verificar se o token expirou
  const checkTokenExpiration = (exp?: number): boolean => {
    const currentTime = Math.floor(Date.now() / 1000);
    return exp ? exp < currentTime : true;
  };

  useEffect(() => {
    const originalXHR = XMLHttpRequest.prototype.send;
    XMLHttpRequest.prototype.send = function (...args) {
      this.addEventListener('load', function () {
        try {
          const responseData = JSON.parse(this.responseText);
          const token = responseData.access_token;

          if (token) {
            const decoded = jwtDecode<DecodedToken>(token);
            setDecodedToken(decoded);
            setIsTokenExpired(checkTokenExpiration(decoded.exp));
            console.log('Decoded Token:', decoded);
            sessionStorage.setItem('kctoken', JSON.stringify(decoded));

          } else {
            console.warn('Access Token não encontrado na resposta.');
          }
        } catch (error) {
          console.error('Erro ao processar a resposta:', error);
        }
      });

      originalXHR.apply(this, args); // Executa a requisição original
    };
  }, []);

  return (
    <AuthContext.Provider value={{ decodedToken, setDecodedToken, isTokenExpired }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth deve ser usado dentro de um AuthProvider');
  }
  return context;
};
