import React, { useEffect, useState } from "react";
import { Sheet, Typography, Button, Box } from "@mui/joy";
import { useAuth } from '../utils/AuthContext';
import keycloak from './../keycloak'; // Importando a configuração do Keycloak
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate  } from 'react-router-dom';

export function TopBar() {
  const { decodedToken, isTokenExpired } = useAuth();
  const [hasRedirected, setHasRedirected] = useState(false);
  const login = () => keycloak?.login();
  const logout = () => {
    setHasRedirected(false); // Reseta a flag ao deslogar
    keycloak?.logout();
  }
  const navigate = useNavigate();

  // Redirecionar para "/home" uma única vez se o token for válido
  useEffect(() => {
    if (decodedToken && !isTokenExpired && !hasRedirected) {
      navigate("/home");
      setHasRedirected(true); // Marca como redirecionado
    }
  }, [decodedToken, isTokenExpired, hasRedirected, navigate]);

  const Page = (page: string) => {
    navigate(page);
  };

  return (
    <Sheet
      component="header"
      sx={{
        position: "sticky",
        top: 0,
        zIndex: 1000,
        backgroundColor: "background.body",
        borderBottom: "1px solid",
        borderColor: "divider",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "0.5rem 1rem",
        boxShadow: "sm",
      }}
    >
      <Typography component="h6" fontSize="lg" sx={{ flexGrow: 1 }}>
        Viver de Mapa {decodedToken?.name}
      </Typography>
      <Box>
      {decodedToken && !isTokenExpired ? (
          <Button onClick={() => Page('/personal')} variant="plain" color="neutral" sx={{ mx: 1 }}>
            Nova pesquisa
          </Button>
        ) : (
          <Button onClick={login} variant="plain" color="neutral" sx={{ mx: 1 }}>
            Login
          </Button>
        )}
        <Button variant="plain" color="neutral" sx={{ mx: 1 }}>
          Sobre
        </Button>
        <Button variant="plain" color="neutral" sx={{ mx: 1 }}>
          Contato
        </Button>
        {decodedToken && !isTokenExpired ? (
        <Button onClick={logout} variant="plain" color="neutral" sx={{ mx: 1 }}>
          Logout
        </Button>
      ) : null}
      </Box>
    </Sheet>
  );
}
