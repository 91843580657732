import Keycloak from 'keycloak-js';

const keycloak = Keycloak({
  url: 'https://crud.num.viverdemapa.com.br:8443/',  // URL do seu servidor Keycloak
  realm: 'viver-de-mapa',  // Nome do seu realm
  clientId: 'client_app_viver_de_mapa',  // ID do seu cliente
});


export default keycloak;
